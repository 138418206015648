// LocaleData.js
class LocaleDetail {
  constructor(id, name, headerImg, localeType, hyperlink, desc, coordinates) {
    this.id = id;
    this.name = name;
    this.headerImage = headerImg;
    this.localeType = localeType;
    this.hyperlink = hyperlink;
    this.description = desc;
    this.coordinates = coordinates;
  }
}

export default LocaleDetail;
