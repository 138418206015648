// LoadingPanel.js
import { useSelector } from "react-redux";
import "../css/loadingPanel.css";

function LoadingPanel() {
  const mapLoaded = useSelector((state) => state.mapLoaded);
  const geolocationLoaded = useSelector((state) => state.geolocationLoaded);

  return (
    <div className={`loadingPanel ${mapLoaded && geolocationLoaded ? "fadeIn-loading" : ""}`}>
      <img src={`${process.env.PUBLIC_URL}/images/logos/loading-logo.png`} alt="Loading" className="loading-logo" />
    </div>
  );
}

export default LoadingPanel;
