// DetailsPanel.js

import React, { useState, useEffect, useRef } from "react";
import { fetchLocaleDetail } from "../services/Database.js";

import { useSelector, useDispatch } from "react-redux";
import { Status, setLocaleId, setLocaleCoordinates, setRouteName, setStatus, setErrorReminder } from "../services/Store.js";

import "../css/detailsPanel.css";

function DetailsPanel() {
  const containerRef = useRef(null);
  const [localeData, setLocaleData] = useState(null);
  const [dockState, setDockState] = useState(0);

  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const localeId = useSelector((state) => state.localeId);
  const geolocationEnabled = useSelector((state) => state.geolocationEnabled);

  const handleClose = () => {
    setDockState(0);
    dispatch(setStatus(Status.BASE));
    dispatch(setLocaleId(null));
  };

  const handleClick = () => {
    if (geolocationEnabled) {
      setDockState(0);
      dispatch(setStatus(Status.ROUTES));
      dispatch(setLocaleCoordinates(localeData.coordinates));
      dispatch(setRouteName(localeData.name));
    } else {
      dispatch(setErrorReminder("Geolocation has to be enabled!"));
    }
  };

  //#region Fetches Data
  // - Fetches the locale data when locale id changes
  useEffect(() => {
    // Fetch locale data using the localeId
    const fetchData = async () => {
      // If localeId is not null fetch data and display dock state 0
      if (status !== Status.ROUTES && status !== Status.SIDEBAR && localeId != null) {
        // Fetch locale data using localeId from Redux store
        const data = await fetchLocaleDetail(localeId);
        setLocaleData(data);
        setDockState(2);
        dispatch(setStatus(Status.DETAILS));
      } // On start set dockState to 0
      else {
        setLocaleData(null);
        setDockState(0);
      }
    };

    fetchData(); // Fetch data only if localeId is not null
  }, [localeId, status, dispatch]); // Re-run the effect whenever localeId changes
  //#endregion

  //#region User Input
  // - Handles swipes and key controls
  useEffect(() => {
    const container = containerRef.current;

    // Event handler for touch swipe
    const handleSwipe = (e) => {
      if (localeData === null) return;

      const touchStartY = e.touches[0].clientY;
      let touchEndY;

      //Handles the start of touch
      const handleTouchMove = (moveEvent) => {
        touchEndY = moveEvent.touches[0].clientY;
      };

      //Handles the end of touch
      const handleTouchEnd = () => {
        if (touchEndY < touchStartY) {
          //Swipe up
          setDockState((prevState) => (prevState < 2 ? prevState + 1 : prevState));
        } else if (touchEndY > touchStartY) {
          //Swipe down
          setDockState((prevState) => (prevState > 1 ? prevState - 1 : prevState));
        }
      };

      container.addEventListener("touchmove", handleTouchMove);
      container.addEventListener("touchend", handleTouchEnd);

      return () => {
        container.removeEventListener("touchmove", handleTouchMove);
        container.removeEventListener("touchend", handleTouchEnd);
      };
    };

    // Event handler for key press
    const handleKeyPress = (e) => {
      if (localeData === null) return;

      const keyCode = e.keyCode;

      if (keyCode === 38) {
        // Up arrow key
        setDockState((prevState) => (prevState < 2 ? prevState + 1 : prevState));
      } else if (keyCode === 40) {
        // Down arrow key
        setDockState((prevState) => (prevState > 1 ? prevState - 1 : prevState));
      } else if (keyCode === 8) {
        // Backspace key
        setLocaleData(null); // Reset localeData to null
        setDockState(0);
        dispatch(setStatus(Status.BASE));
        dispatch(setLocaleId(null));
      }
    };

    container.addEventListener("keydown", handleKeyPress);
    container.addEventListener("touchstart", handleSwipe);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      container.removeEventListener("keydown", handleKeyPress);
      container.removeEventListener("touchstart", handleSwipe);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [localeData, dispatch]);
  //#endregion

  return (
    <div ref={containerRef} className="detailsPanel">
      <div className={`detailsDock details-dock-${dockState}`}>
        <div className="details-close-button" onClick={handleClose}>
          <div className="details-close-icon"> X </div>
        </div>
        <h1>{localeData ? localeData.name : ""}</h1>
      </div>
      <div className={`detailsCard details-card-${dockState}`}>
        <div className="details-close-button" onClick={handleClose}>
          <div className="details-close-icon"> X </div>
        </div>
        <div className="detailsBody">
          {localeData && localeData.headerImage && localeData.headerImage[0] ? (
            <div className="detailsHeader">
              <img src={localeData.headerImage[0].url} alt="header" />
              <h3>{localeData ? localeData.name : ""}</h3>
            </div>
          ) : (
            <div className="noDetailsHeader">
              <h3>{localeData ? localeData.name : ""}</h3>
            </div>
          )}
          {localeData && localeData.description ? (
            <div className="detailsText">
              <h4>{localeData && localeData.localeType ? localeData.localeType : ""}</h4>
              {localeData.hyperlink ? (
                <div>
                  <a href={localeData.hyperlink}>{localeData.hyperlink}</a>
                  <pre>{localeData && localeData.description ? localeData.description : ""}</pre>
                </div>
              ) : (
                <pre>{localeData && localeData.description ? localeData.description : ""}</pre>
              )}
            </div>
          ) : (
            <div className="noDetailsText">
              <h4>{localeData && localeData.localeType ? localeData.localeType : ""}</h4>
            </div>
          )}
        </div>
        <button onClick={handleClick} className="directions-button">
          <div className="directions-button-content">
            <h2>Directions</h2>
            <div className="directions-icon">
              <img src={`${process.env.PUBLIC_URL}/images/symbols/directions-icon.png`} alt="dir-icon" />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default DetailsPanel;
