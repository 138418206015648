// Header.js
import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { Status, setStatus } from "../services/Store.js";

import "../css/header.css";

function Header() {
  const currentStatus = useSelector((state) => state.status);
  const dispatch = useDispatch();

  const handleClick = () => {
    if (currentStatus !== Status.ROUTES) {
      if (currentStatus !== Status.SIDEBAR) dispatch(setStatus(Status.SIDEBAR));
      else dispatch(setStatus(Status.BASE));
    }
  };

  return (
    <header className="header">
      <div className="nonEmergency">
        Call <a href="tel:317-327-3811">317-327-3811</a>
        <br></br> for any non-emergencies
      </div>
      <button type="button" onClick={handleClick}>
        <div className="hamburger-menu">
          <div className="menu-line"></div>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
        </div>
      </button>
    </header>
  );
}

export default Header;
