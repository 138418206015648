//ErrorPanel.js
import { useState, useEffect } from "react";

import "../css/errorPanel.css";

import { useDispatch, useSelector } from "react-redux";
import { setGeolocationEnabled, setGeolocationLoaded, setErrorReminder } from "../services/Store";

function ErrorPanel() {
  const dispatch = useDispatch();
  const errorReminder = useSelector((state) => state.errorReminder);
  const [timerOn, setTimerOn] = useState(false);

  // Checks the user geolocation permissions
  const checkGeolocationPermissions = async () => {
    try {
      if (navigator.geolocation) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error checking geolocation permissions:", error);
      return false;
    }
  };

  // Check geolocation permissions when component mounts
  useEffect(() => {
    const checkPermissions = async () => {
      //Check geolocation permissions
      const permissionsGranted = await checkGeolocationPermissions();
      dispatch(setGeolocationEnabled(permissionsGranted));
      dispatch(setGeolocationLoaded(true));
    };
    const permissionChangeListener = async () => {
      //Check geolocation permissions
      const permissionsGranted = await checkGeolocationPermissions();
      dispatch(setGeolocationEnabled(permissionsGranted));
      if (permissionsGranted) dispatch(setErrorReminder(""));
    };

    checkPermissions();

    // Check permissions periodically every 20 seconds
    const intervalId = setInterval(permissionChangeListener, 20000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  //- Removes errorReminder after 3 seconds
  useEffect(() => {
    if (errorReminder !== "" && !timerOn) {
      setTimerOn(true);
      setTimeout(() => {
        dispatch(setErrorReminder(""));
        setTimerOn(false);
      }, 3000);
    }
  }, [dispatch, errorReminder, timerOn]);

  return (
    <div className={`errorPanel ${errorReminder !== "" ? "show-error" : "hide-error"}`}>
      {errorReminder !== "" && <h1>{errorReminder}</h1>}
    </div>
  );
}

export default ErrorPanel;
