// Sidebar.js
import "../css/sidebar.css";

import { useSelector, useDispatch } from "react-redux";
import { Status, MapDisplays, setStatus, setMapDisplay } from "../services/Store.js";

function Sidebar() {
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setStatus(Status.BASE));
  };

  const handleQueryAll = () => {
    dispatch(setMapDisplay(MapDisplays.All_Locales));
  };

  const handleQueryHotels = () => {
    dispatch(setMapDisplay(MapDisplays.Hotel));
  };

  const handleQueryRestaurants = () => {
    dispatch(setMapDisplay(MapDisplays.Restaurant));
  };

  const handleQueryEvents = () => {
    dispatch(setMapDisplay(MapDisplays.Event));
  };

  const handleQueryGoldMedals = () => {
    dispatch(setMapDisplay(MapDisplays.Gold_Medals));
  };

  return (
    <div className={`sidebar-container ${status === "SIDEBAR" ? "show-sidebar" : "hide-sidebar"}`}>
      <div className="sidebar-close-button" onClick={handleClose}>
        <div className="sidebar-close-icon"> X </div>
      </div>

      <div className="sidebar">
        <div className="menu-item" onClick={handleQueryAll}>
          <h2> All locales </h2>
        </div>
        <div className="menu-item" onClick={handleQueryHotels}>
          <h2> Hotels </h2>
        </div>
        <div className="menu-item" onClick={handleQueryRestaurants}>
          <h2> Restaurants </h2>
        </div>
        <div className="menu-item" onClick={handleQueryEvents}>
          <h2> Events </h2>
        </div>
        <div className="menu-item" onClick={handleQueryGoldMedals}>
          <h2> Gold Medal Offers </h2>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
