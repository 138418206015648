// App.js
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import store from "./services/Store.js";

import Header from "./components/Header.js";
import LoadingPanel from "./components/LoadingPanel.js";
import ErrorPanel from "./components/ErrorPanel.js";
import Sidebar from "./components/Sidebar.js";
import Map from "./components/Map.js";
import DetailsPanel from "./components/DetailsPanel.js";
import RoutePanel from "./components/RoutePanel.js";
import Footer from "./components/Footer.js";

//importing styles
import "./css/structure.css";

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <Helmet>
          <title>Experience Indy | 2024 U.S Olympic Team Swimming Trials</title>
        </Helmet>
        <div className="App">
          <LoadingPanel />
          <ErrorPanel />
          <Header />
          <Sidebar />
          <Map />
          <DetailsPanel />
          <RoutePanel />
          <Footer />
        </div>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
