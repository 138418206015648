//Store.js
import { configureStore } from "@reduxjs/toolkit";

// Define the enum object
export const Status = {
  BASE: "BASE",
  SIDEBAR: "SIDEBAR",
  DETAILS: "DETAILS",
  ROUTES: "ROUTES",
};

// Define the enum object
export const MapDisplays = {
  All_Locales: "All_Locales",
  Hotel: "Hotel",
  Restaurant: "Restaurant",
  Event: "Event",
  Route: "Route",
  Gold_Medals: "Gold_Medals",
};

// Action types
const HAS_MAP_LOADED = "HAS_MAP_LOADED";
const HAS_GEOLOCATION_LOADED = "HAS_GEOLOCATION_LOADED";

const SET_STATUS = "SET_STATUS";
const SET_MAP_DISPLAY = "SET_MAP_DISPLAY";
const SET_GEOLOCATION_ENABLED = "SET_GEOLOCATION_ENABLED";

const SET_LOCALE_ID = "SET_LOCALE_ID";
const SET_LOCALE_COORDS = "SET_LOCALE_COORDS";
const SET_USER_COORDS = "SET_USER_COORDS";

const SET_ROUTE_NAME = "SET_ROUTE_NAME";
const SET_ROUTE_DISTANCE = "SET_ROUTE_DISTANCE";
const SET_ROUTE_DURATION = "SET_ROUTE_DURATION";

const SET_ERROR_REMINDER = "SET_ERROR_REMINDER";

// Action creators
export const setMapLoaded = (mapLoaded) => ({
  type: HAS_MAP_LOADED,
  payload: mapLoaded,
});
export const setGeolocationLoaded = (geolocationLoaded) => ({
  type: HAS_GEOLOCATION_LOADED,
  payload: geolocationLoaded,
});
export const setStatus = (status) => ({
  type: SET_STATUS,
  payload: status,
});
export const setMapDisplay = (mapDisplay) => ({
  type: SET_MAP_DISPLAY,
  payload: mapDisplay,
});
export const setGeolocationEnabled = (geolocationEnabled) => ({
  type: SET_GEOLOCATION_ENABLED,
  payload: geolocationEnabled,
});
export const setLocaleId = (localeId) => ({
  type: SET_LOCALE_ID,
  payload: localeId,
});
export const setLocaleCoordinates = (localeCoordinates) => ({
  type: SET_LOCALE_COORDS,
  payload: localeCoordinates,
});
export const setUserCoordinates = (userCoordinates) => ({
  type: SET_USER_COORDS,
  payload: userCoordinates,
});
export const setRouteName = (routeName) => ({
  type: SET_ROUTE_NAME,
  payload: routeName,
});
export const setRouteDistance = (routeDistance) => ({
  type: SET_ROUTE_DISTANCE,
  payload: routeDistance,
});
export const setRouteDuration = (routeDuration) => ({
  type: SET_ROUTE_DURATION,
  payload: routeDuration,
});
export const setErrorReminder = (errorText) => ({
  type: SET_ERROR_REMINDER,
  payload: errorText,
});

// Reducer
const initialState = {
  mapLoaded: false,
  geolocationLoaded: false,

  status: Status.BASE,
  mapDisplay: MapDisplays.All_Locales,
  geolocationEnabled: null,

  localeId: null,
  localeCoordinates: [],
  userCoordinates: [],

  routeName: null,
  routeDistance: null,
  routeDuration: null,

  errorReminder: "",
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HAS_MAP_LOADED:
      return { ...state, mapLoaded: action.payload };
    case HAS_GEOLOCATION_LOADED:
      return { ...state, geolocationLoaded: action.payload };
    case SET_STATUS:
      return { ...state, status: action.payload };
    case SET_MAP_DISPLAY:
      return { ...state, mapDisplay: action.payload };
    case SET_GEOLOCATION_ENABLED:
      return { ...state, geolocationEnabled: action.payload };
    case SET_LOCALE_ID:
      return { ...state, localeId: action.payload };
    case SET_LOCALE_COORDS:
      return { ...state, localeCoordinates: action.payload };
    case SET_USER_COORDS:
      return { ...state, userCoordinates: action.payload };
    case SET_ROUTE_NAME:
      return { ...state, routeName: action.payload };
    case SET_ROUTE_DISTANCE:
      return { ...state, routeDistance: action.payload };
    case SET_ROUTE_DURATION:
      return { ...state, routeDuration: action.payload };
    case SET_ERROR_REMINDER:
      return { ...state, errorReminder: action.payload };
    default:
      return state;
  }
};

// Store
const store = configureStore({
  reducer: reducer,
});

export default store;
