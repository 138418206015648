//RoutePanel.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Status, MapDisplays, setStatus, setMapDisplay, setRouteName, setLocaleCoordinates, setLocaleId } from "../services/Store.js";

import "../css/routePanel.css";

function RoutePanel() {
  const [formattedDistance, setFormattedDistance] = useState("");
  const [formattedDuration, setFormattedDuration] = useState("");
  const dispatch = useDispatch();
  const status = useSelector((state) => state.status);
  const routeName = useSelector((state) => state.routeName);
  const routeDistance = useSelector((state) => state.routeDistance);
  const routeDuration = useSelector((state) => state.routeDuration);

  // Determine dock-state classes based on routeName
  const dockStateHeader = status === Status.ROUTES ? "route-header-show" : "route-header-hide";
  const dockStateContent = status === Status.ROUTES ? "route-content-show" : "route-content-hide";

  const handleClose = () => {
    dispatch(setStatus(Status.BASE));
    dispatch(setMapDisplay(MapDisplays.All_Locales));
    dispatch(setLocaleId(null));
    dispatch(setRouteName(null));
    dispatch(setLocaleCoordinates([]));
  };

  useEffect(() => {
    if (status === Status.ROUTES) {
      dispatch(setMapDisplay(MapDisplays.Route));

      // Convert duration to hours if duration is more than 1 hour, otherwise in minutes
      const duration = routeDuration > 3600 ? `${(routeDuration / 3600).toFixed(1)} hours` : `${(routeDuration / 60).toFixed(1)} minutes`;
      setFormattedDuration(duration);

      // Convert distance to miles
      const distant = `${(routeDistance / 1609.344).toFixed(1)} miles`;
      setFormattedDistance(distant);
    }
  }, [status, routeDistance, routeDuration, dispatch]);

  return (
    <div className="routePanel">
      <div className={`routeHeader ${dockStateHeader}`}>{routeName && <h2>{routeName}</h2>}</div>

      <div className={`routeContent ${dockStateContent}`}>
        <div className="routeInfo">
          <div className="route-close-button" onClick={handleClose}>
            <div className="route-close-icon"> X </div>
          </div>
          {routeName && (
            <>
              <h2>Distance: {formattedDistance}</h2>
              <h3>Time: {formattedDuration}</h3>
            </>
          )}
        </div>
        <div className="routeTypeIcon">
          <img src={`${process.env.PUBLIC_URL}/images/symbols/walking-logo.png`} alt="walking" className="walking-logo" />
        </div>
      </div>
    </div>
  );
}

export default RoutePanel;
