//Footer.js
import React from "react";
import "../css/footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div>
        <img className="ISC-logo" src={`${process.env.PUBLIC_URL}/images/logos/ISC Primary One-Color/ISC-Logo_White.png`} alt="ISC-logo" />
      </div>
      <div>
        <img className="lanes-logo" src={`${process.env.PUBLIC_URL}/images/logos/LanesLeadtoIndy.png`} alt="Lanes-Lead-to-Indy" />
      </div>
      <div>
        <img className="luddy-logo" src={`${process.env.PUBLIC_URL}/images/logos/luddy-logo.png`} alt="Luddy" />
      </div>
    </footer>
  );
}

export default Footer;
