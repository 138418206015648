// MapControls.js
import "../css/mapControls.css";

class MapControls {
  constructor(options) {
    this.options = options;
  }

  setCurrentLocation(userCoordinates) {
    this.userCoordinates = userCoordinates; // Store the user's coordinates
  }

  onAdd(map) {
    this.map = map;

    // Create container for custom control
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl map-controls";

    // Button to fly to the center of indianapolis
    const indyLocationButton = document.createElement("button");
    indyLocationButton.className = "map-control-button";
    indyLocationButton.innerHTML = `<img src="${process.env.PUBLIC_URL}/images/symbols/zoom_Indy.svg" alt="Zoom To Indy" />`;
    indyLocationButton.addEventListener("click", () => {
      this.flyToIndianapolis();
    });
    this.container.appendChild(indyLocationButton);

    // Button to fly to user's location
    const userLocationButton = document.createElement("button");
    userLocationButton.className = "map-control-button";
    userLocationButton.innerHTML = `<img src="${process.env.PUBLIC_URL}/images/symbols/zoom_Me.svg" alt="Zoom To Me" />`;
    userLocationButton.addEventListener("click", () => {
      this.flyToUserLocation();
    });
    this.container.appendChild(userLocationButton);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }

  flyToIndianapolis() {
    this.map.flyTo({
      center: [-86.16391969084013, 39.76030151771834],
      essential: true,
    });
  }

  flyToUserLocation() {
    if (this.userCoordinates) {
      this.map.flyTo({
        center: this.userCoordinates,
        essential: true,
      });
    }
  }
}
export default MapControls;
