// Locale.js
class Locale {
  constructor(id, name, latitude, longitude, icon) {
    this.id = id;
    this.name = name;
    this.latitude = latitude;
    this.longitude = longitude;
    this.icon = icon;
  }
}

export default Locale;
