// fetchData.js
import axios from "axios";
import Locale from "./Locale";
import LocaleDetail from "./LocaleDetail";

const accessToken = "patzuUHXERvFQ5AwS.318cdf503e6fb7abd4809932878222a08a116821ca997ce6c4f12326b24ee553";

//#region FETCH ALL
// Fetches the first 25 locale coordinates marked as priority and gold
export const fetchPriorityGoldLocales = async () => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({Priority?} = "1", {Gold Medal?} = "1")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, true, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values
    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches the first 25 locale coordinates marked as priority but not gold
export const fetchPriorityLocales = async () => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({Priority?} = "1", {Gold Medal?} = "")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, false, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values
    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches the first 25 locale coordinates marked as gold but not priority
export const fetchGoldLocales = async () => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({Priority?} = "", {Gold Medal?} = "1")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, true, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values
    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches a random selection of 25 locations from a max of 50
export const fetchLocales = async () => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 50,
        filterByFormula: `AND({Priority?} = "", {Gold Medal?} = "")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, false, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values

    // Shuffle the array of locales
    const shuffledLocales = shuffle(locales);

    // Return the first 25 elements (randomly selected)
    return shuffledLocales.slice(0, 25);
  } catch (error) {
    // Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};
//#endregion

//#region FETCH FILTERED
// Fetches the first 25 locale coordinates marked as priority and gold via filter
export const fetchPriorityGoldLocalesByFilter = async (fieldName, value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({${fieldName}} = "${value}", {Priority?} = "1", {Gold Medal?} = "1")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, true, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values

    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches the first 25 locale coordinates marked as priority but not gold via filter
export const fetchPriorityLocalesByFilter = async (fieldName, value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({${fieldName}} = "${value}", {Priority?} = "1", {Gold Medal?} = "")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, false, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values

    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches the first 25 locale coordinates marked as gold but not priority via filter
export const fetchGoldLocalesByFilter = async (fieldName, value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 25,
        filterByFormula: `AND({${fieldName}} = "${value}", {Priority?} = "", {Gold Medal?} = "1")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, true, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values

    return locales;
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};

// Fetches a random selection of 25 locations from a max of 50 via filter
export const fetchLocalesByFilter = async (fieldName, value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        maxRecords: 50,
        filterByFormula: `AND({${fieldName}} = "${value}", {Priority?} = "", {Gold Medal?} = "")`,
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    // Maps response data to an array of Locale objects
    const locales = response.data.records
      .map((record) => {
        const { Id, Name, Coordinates } = record.fields;
        const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
        const localeType = record.fields["Locale Type"] || "";

        if (lat === null || lon === null) {
          return null;
        } else {
          return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, false, localeType));
        }
      })
      .filter((locale) => locale !== null); // Filter out null values

    // Shuffle the array of locales
    const shuffledLocales = shuffle(locales);

    // Return the first 25 elements (randomly selected)
    return shuffledLocales.slice(0, 25);
  } catch (error) {
    //Returns an empty array if there is an error
    console.error(error);
    return [];
  }
};
//#endregion

//#region FETCH SINGLE
// Fetches a single locale on ID
export const fetchLocale = async (value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        filterByFormula: `{Id} = "${value}"`, // Assuming ID is the field name
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    //Gets data from response.data.records
    const { Id, Name, Coordinates } = response.data.records[0].fields;
    const [lat, lon] = Coordinates ? Coordinates.split(",") : [null, null];
    const localeType = response.data.records[0].fields["Locale Type"] || "";
    const goldMedal = response.data.records[0].fields["Gold Medal?"] ? "true" : "false";

    if (lat === null || lon === null) {
      return null;
    } else {
      return new Locale(Id, Name, parseFloat(lat), parseFloat(lon), getIcon(Name, goldMedal, localeType));
    }
  } catch (error) {
    console.error(error);
    return null; // Returning null instead of an empty array in case of error
  }
};
// Fetches a single locale details on ID
export const fetchLocaleDetail = async (value) => {
  try {
    const response = await axios.get("https://api.airtable.com/v0/app1pS972cgNktFFd/Locales", {
      params: {
        filterByFormula: `{Id} = "${value}"`, // Assuming ID is the field name
        view: "Grid view",
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    //Gets data from response.data.records
    const { Id, Name, Description, Coordinates, Hyperlinks } = response.data.records[0].fields;
    const HeaderImage = response.data.records[0].fields["Header Image"];
    const LocaleType = response.data.records[0].fields["Locale Type"];
    const [lng, lat] = Coordinates.split(",");
    const localeDetail = new LocaleDetail(Id, Name, HeaderImage, LocaleType, Hyperlinks, Description, [parseFloat(lat), parseFloat(lng)]);

    return localeDetail;
  } catch (error) {
    console.error(error);
    return null; // Returning null instead of an empty array in case of error
  }
};
//#endregion

// Function to shuffle an array
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Determines if the icon image
const getIcon = (name, gold, localeType) => {
  if (name === "Lucas Oil Stadium") return "lucas-oil";
  else if (name === "Eiffel Tower") return "eiffel-tower";
  else if (name === "Aqua Zone" || name === "USA Swimming Live Concert Stage") return "star";
  else if (localeType === "Hospital") return "hospital";
  else if (gold === true) {
    if (localeType === "Attraction") return "gold-attraction";
    else if (localeType === "Bar") return "gold-bar";
    else if (localeType === "Book Store") return "gold-bookstore";
    else if (localeType === "Event") return "gold-event";
    else if (localeType === "Golf") return "gold-golf";
    else if (localeType === "Hotel") return "gold-hotel";
    else if (localeType === "Monument") return "gold-monument";
    else if (localeType === "Music") return "gold-music";
    else if (localeType === "Parking Lot") return "gold-parking";
    else if (localeType === "Photo Op") return "gold-photo";
    else if (localeType === "Restaurant") return "gold-restaurant";
    else if (localeType === "Retail") return "gold-retail";
    else return "gold-locale";
  } else if (gold === false) {
    if (localeType === "Attraction") return "attraction";
    else if (localeType === "Bar") return "bar";
    else if (localeType === "Book Store") return "bookstore";
    else if (localeType === "Event") return "event";
    else if (localeType === "Golf") return "golf";
    else if (localeType === "Hotel") return "hotel";
    else if (localeType === "Monument") return "monument";
    else if (localeType === "Music") return "music";
    else if (localeType === "Parking Lot") return "parking";
    else if (localeType === "Photo Op") return "photo";
    else if (localeType === "Restaurant") return "restaurant";
    else if (localeType === "Retail") return "retail";
    else return "locale";
  } else return "locale";
};
